<template>
  <div class="wrapper">
    <!-- Test -->
    <PaymentModal
      v-if="$route.query.paymentModalDemo"
      :amount="15"
      note="(3 days)"
    ></PaymentModal>

    <div class="image-box mx-auto mt-5 mt-sm-0 pt-5 pt-sm-0">
      <img class="image" :src="require('@/assets/pics/logo_black.png')" />
    </div>
    <div class="alert alert-danger mb-3" v-if="$route.query['account-deleted']">
      Konto gelöscht.
    </div>
    <div class="box">
      <div class="link">
        <ButtonElement
          link
          :anchor="{ name: 'Login', params: { role: 'admin' } }"
        >
          <!-- Admin login -->
          <div class="image-box">
            <img
              class="image icon"
              :src="require('@/assets/pics/company_icon.png')"
            />
          </div>
          Hoodty für Unternehmen
        </ButtonElement>
      </div>
      <!-- <div class="link">
        <ButtonElement
          link
          :anchor="{ name: 'Login', params: { role: 'staff' } }"
        >
          Staff login
        </ButtonElement>
      </div> -->
      <div class="link">
        <ButtonElement
          link
          :anchor="{ name: 'Login', params: { role: 'user' } }"
        >
          <!-- User login -->
          <div class="image-box">
            <img
              class="image icon"
              :src="require('@/assets/pics/user_icon.png')"
            />
          </div>
          Hoodty für Endnutzer
        </ButtonElement>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import PaymentModal from '../components/Payment/PaymentModal.vue';
export default {
  components: { ButtonElement, PaymentModal },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  background-image: url('../assets/pics/pexels-burst-374006.jpg');
}
.box {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $hty-radius;
  border: $hty-border;
  background-color: white;
  padding: 1rem 2.5rem;
  max-width: 400px;
  min-width: 300px;
  text-align: center;
  margin-bottom: 10rem;

  .button {
    display: block;
  }

  .image-box {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
}

.image.icon {
  width: 60px;
  margin-top: 10px;
}

.image-box {
  margin-bottom: 30px;
  text-align: center;
}
.image {
  width: 200px;
}
.link {
  margin: 20px 0;
}
</style>
